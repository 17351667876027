// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-gavidigest-src-gatsby-theme-gaviscon-pages-404-tsx": () => import("./../../../../gatsby-theme-gavidigest/src/gatsby-theme-gaviscon/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-gavidigest-src-gatsby-theme-gaviscon-pages-404-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-article-listing-page-article-listing-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/ArticleListingPage/ArticleListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-article-listing-page-article-listing-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-article-page-article-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-article-page-article-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-product-listing-page-product-listing-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/ProductListingPage/ProductListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-product-listing-page-product-listing-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-quiz-page-quiz-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/QuizPage/QuizPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-quiz-page-quiz-page-tsx" */),
  "component---gatsby-theme-gaviscon-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-gaviscon/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-gaviscon-src-templates-sitemap-page-sitemap-page-tsx" */)
}

